import React from "react"
import "./content-site-map.scss"
import siteUrl from "../../../shared/utils/siteUrl"

const ContentSiteMap = () => {

    const siteMap = [
        {
            name: "Somos Grupo Familia®",
            url: "somos-grupo-familia/",
            sons: []
        },
        {
            name: "Nuestras Marcas",
            url: "nuestras-marcas/",
            sons: []
        },
        {
            name: "Sostenibilidad",
            url: "sostenibilidad/",
            sons: []
        },
        {
            name: "Innovación",
            url: "innovacion/",
            sons: []
        },
        {
            name: "Transformación Digital",
            url: "transformacion-digital/",
            sons: []
        },
        {
            name: "Inversionistas",
            url: "inversionistas/",
            sons: [
                /* {
                    name: "Información Accionistas",
                    url: "inversionistas/informacion-accionistas/"
                },
                {
                    name: "Gobierno Corporativo",
                    url: "inversionistas/gobierno-corporativo/"
                },
                {
                    name: "Información Financiera",
                    url: "inversionistas/informacion-financiera/"
                } */
            ]
        }


    ]


    const generals = [
        {
            name: "Trabaja con Nosotros",
            url: "trabaja-con-nosotros/",
            openTab: false
        },
        {
            name: "Fundación Grupo Familia®",
            url: "fundacion-grupo-familia/",
            openTab: false
        },
        {
            name: "Buscar",
            url: "buscador/",
            openTab: false
        },
        {
            name: "Contáctanos",
            url: "contactanos/",
            openTab: false
        },
        {
            name: "Mapa del sitio",
            url: "mapa-del-sitio/",
            openTab: false
        },
        {
            name: "Términos y Condiciones",
            url: "terminos-y-condiciones/",
            openTab: false
        },
        {
            name: "Política de Protección de Datos",
            url: "politica-proteccion-de-datos.pdf",
            openTab: true
        }

    ]

    return (
      <section className="f-section-site-map">
          <div className="f-section-site-map__wrapper">
              <h1 className="f-section-site-map__title">Mapa del sitio</h1>
              <div className="f-section-site-map__content">

                  <div className="f-section-site-map__principals">
                      <h2 className="f-section-site-map__sub-title">Menú principal</h2>

                      {siteMap &&
                      siteMap.map((data, index) => {
                          return (
                            <div className="f-section-site-map__links" key={index}>
                                <a href={siteUrl + "/" + data.url} target="_self">{data.name}</a>
                                {data.sons.length > 0 && (
                                  data.sons.map((item, index2) => {
                                      return (
                                        <div className="f-section-site-map__sub-links" key={`son${index2}`} ><a
                                          href={siteUrl + "/" + item.url}
                                          target="_self">{item.name}</a>
                                        </div>
                                      )
                                  })
                                )}

                            </div>
                          )
                      })}
                  </div>
                  <div className="f-section-site-map__generals">
                      <h2 className="f-section-site-map__sub-title">Transversales</h2>
                      <div className="f-section-site-map__generals-content">
                          {generals &&
                          generals.map((data, index) => {
                              return (
                                <div className="f-section-site-map__general-links" key={index}>
                                    <a href={siteUrl + "/" + data.url}
                                        target={data.openTab ? "_blank" : "_self"}
                                    >
                                        {data.name}
                                    </a>
                                </div>
                              )
                          })}
                      </div>
                  </div>

              </div>

          </div>
      </section>
    )
}

export default ContentSiteMap

import React from "react"
import Layout from "../shared/components/organisms/layout/layout"
import SEO from "../shared/components/atoms/seo/seo"
import "../shared/styles/index.scss"
import ContentSiteMap from "../components/organisms/content-site-map/content-site-map"

const MapaDelSitio = (props) => {
    return (
      <Layout>
          <SEO
            title="Mapa del Sitio"
            description="Mapa del Sitio"
          />
          <ContentSiteMap />
      </Layout>
    )
}

export default MapaDelSitio
